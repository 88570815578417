<template>
  <div class="container" v-if="page">
    <div class="content" style="text-align: center; padding: 80px 0 40px 0">
      <h1>{{ page.title }}</h1>
    </div>
    <div class="content">
      <div class="row" v-if="content(page.content_html)[1]">
        <div class="col-xs-12 col-md-4" style="margin-right: 40px">
          <p class="wptext" v-html="content(page.content_html)[0]"></p>
        </div>
        <div class="col-xs-12 col-md-7">
          <p class="wptext" v-html="content(page.content_html)[1]"></p>
        </div>
      </div>

      <div class="row" v-else>
        <div class="col-xs-12 col-md-6 col-md-offset-3">
          <p class="wptext" v-html="content(page.content_html)[0]"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data: () => ({}),
  computed: {
    page() {
      let pages = this.$store.state.work;
      let page;

      Object.keys(pages).forEach(key => {
        if (pages[key].id.split("?p=").pop() === this.id) {
          page = pages[key];
        }
      });

      return page;
    }
  },
  methods: {
    content(str) {
      str = str.replaceAll("#", "<br>");
      str = str.split("***COL***");
      return str;
    }
  }
};
</script>

<style lang="scss" scoped>
$assets: "~@/assets/images";
</style>
